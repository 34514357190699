<template>
  <a-form ref="formRef" :model="applyAccounts" :rules="rules" :labelCol="{ flex: '120px' }" scrollToFirstError>
    <a-form-item :wrapperCol="{ flex: 'auto' }" label="付款总金额" style="display:flex">
      <a-typography-text type="danger">{{ amount }} 元 </a-typography-text>
      <a-typography-text type="secondary">{{ amountUppercase(amount) }}</a-typography-text>
    </a-form-item>
    <a-form-item label="收款方类型">
      <a-select v-if="!customerType" v-model:value="onlineData.objectType" @change="typeChange">
        <a-select-option :value="2">承运商</a-select-option>
        <a-select-option :value="1">客户</a-select-option>
      </a-select>
      <span v-else>{{ customerType === 2 ? '承运商' : '客户' }}</span>
    </a-form-item>
    <a-form-item v-show="onlineData.objectType" :label="onlineData.objectType === 1 ? '选择客户' : '选择承运商'">
      <a-select v-if="!customerType" placeholder="搜索并选择" v-model:value="clientName" @search="searchClient" labelInValue
        :disabled="customerType" :filterOption="false" show-search>
        <template v-if="fetching" #notFoundContent>
          <a-spin size="small" />
        </template>
        <a-select-option v-for="item in clientList" :key="item.id" @click="carrierClient(item)">
          {{ item.name + '-' + item.mobile }}
        </a-select-option>
      </a-select>
      <span v-else>{{ clientName }}</span>
    </a-form-item>
    <!-- <a-form-item :wrapperCol="{ flex: 'auto' }" label="付款项" v-if="controlCheck">
      <template v-for="form in applyAccounts.forms" :key="form">
        <a-checkbox v-model:checked="form.flag" :disabled="form.amt === 0">线下资料</a-checkbox>
        <a-typography-text type="secondary"> {{ form.amt }} </a-typography-text>
      </template>
    </a-form-item> -->
    <template v-for="(form, index) in applyAccounts.forms" :key="form">
      <template v-if="form.flag">
        <a-divider />
        <div style="display:flex">
          <a-typography-title :level="5">{{ form.projectName }}</a-typography-title>
          <div style="margin-left:25px">
            <span>金额：</span>
            <span>
              <a-typography-text type="danger">{{ form.amt }} 元 </a-typography-text>
              <a-typography-text type="secondary">{{ amountUppercase(form.amt) }}</a-typography-text>
            </span>
          </div>
        </div>
        <a-form-item v-show="form.project == 3">
          <span style="color:#344563;font-weight:700">线上资料</span>
        </a-form-item>
        <a-form ref="onlineFormRef" :model="onlineData" :rules="onlineRules" :labelCol="{ flex: '120px' }"
          scrollToFirstError>
          <a-form-item v-show="onlineData.objectType && form.project == 3"
            :label="onlineData.objectType === 1 ? '客户会员账号' : '承运商会员账号'">
            <a-select placeholder="选择会员账号" v-model:value="vipName">
              <a-select-option v-for="item in VIPaccountList" :key="item.id" @click="chooseVIP(item)">
                {{ item.memberAlias + '-' + item.phone + '-' + item.creator }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item v-show="onlineData.objectType && form.project == 3" label="银行卡号">
            <a-select placeholder="选择卡号" v-model:value="bankNo">
              <a-select-option v-for="item in bankNoList" :key="item.bankCard" @click="chooseBankCarNoChange(item)">
                {{ item.bankName + '-' + item.bankTrueCard }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
        <a-form-item>
          <span style="color:#344563;font-weight:700">线下资料</span>
        </a-form-item>
        <!-- <a-form-item label="承运商抬头" :wrapperCol="{ flex: 'auto' }">
          <a-button type="primary" :loading="loading" @click="choiceCarrier(form)">选择</a-button>
        </a-form-item> -->
        <!-- <a-form-item label="支付方式">
          <a-select v-model:value="form.payMethod" :options="$store.state.enumAll.payTypeEnum" style="width:140px"
            placeholder="支付方式">
          </a-select>
        </a-form-item> -->
        <a-form-item label="收款方式" :wrapperCol="{ flex: 'auto' }" :name="['forms', index, 'mod']"
          :rules="{ required: true, message: '请选择收款方式', trigger: 'change' }">
          <a-select v-model:value="form.mod">
            <a-select-option value="微信">微信</a-select-option>
            <a-select-option value="支付宝">支付宝</a-select-option>
            <a-select-option value="银行卡">银行卡</a-select-option>
          </a-select>
        </a-form-item>
        <!--        <a-form-item label="银行名称" :wrapperCol="{ flex: 'auto' }">-->
        <!--          <a-input v-model:value="form.name" />-->
        <!--        </a-form-item>-->
        <a-form-item label="卡号" :wrapperCol="{ flex: 'auto' }">
          <a-input v-model:value="form.accountNum" />
        </a-form-item>
        <a-form-item label="户名" :wrapperCol="{ flex: 'auto' }">
          <a-input v-model:value="form.accountName" />
        </a-form-item>
        <a-form-item label="开户行" :wrapperCol="{ flex: 'auto' }">
          <a-input v-model:value="form.accountBank" />
        </a-form-item>
        <a-form-item label="收款方信息" :wrapperCol="{ flex: 'auto' }">
          <a-textarea v-model:value="form.payeeInfo" />
        </a-form-item>
        <a-form-item label="收款码" :wrapperCol="{ flex: 'auto' }">
          <a-upload v-model:file-list="form.fileList" accept="image/*" list-type="picture"
            :beforeUpload="(file, fileList) => { form.fileList = [file]; return false }">
            <a-button :disabled="form.fileList && form.fileList.length > 0">
              <CameraOutlined />
              图片
            </a-button>
          </a-upload>
        </a-form-item>
      </template>
    </template>
    <a-form-item :wrapperCol="{ flex: 'auto' }" label="备注">
      <a-textarea v-model:value="applyAccounts.remark" />
    </a-form-item>
    <a-form-item :wrapperCol="{ flex: 'auto' }" label="上传其他图片">
      <a-upload list-type="picture" accept="image/*" v-model:file-list="otherimgList" @preview="handleOtherPreview"
        :before-upload="beforeOtherUpload">
        <div class="clearfix" v-if="otherimgList.length < 8">
          <plus-outlined />
          <div class="ant-upload-text">上传</div>
        </div>
      </a-upload>
    </a-form-item>
  </a-form>
  <a-modal :visible="previewOtherVisible" :footer="null" @cancel="handleOtherCancel">
    <img alt="预览图片" style="width: 100%" :src="previewOtherImage" />
  </a-modal>
  <div :style="{
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: '100%',
    borderTop: '1px solid #e9e9e9',
    padding: '10px 16px',
    background: '#fff',
    textAlign: 'right',
    zIndex: 1,
  }">
    <a-button type="primary" :loading="loading" @click="instanceStart">提交</a-button>
  </div>

  <a-modal :visible="visible" :footer="null" @cancel="handleCancel">
    <a-list rowKey="id" :grid="{ gutter: 24, lg: 1, md: 1, sm: 1, xs: 1 }" :dataSource="listData">
      <template #renderItem="{ item }">
        <a-list-item class="list-item">
          <a-card :hoverable="true" class="modal_card">
            <a-card-meta>
              <template #title>
                <a>{{ item.name }}</a>
              </template>
              <template #avatar>
                <a-avatar class="card-avatar" :src="item.avatar" size="large" />
              </template>
              <template #description>
                {{ item.taxIdentNum }}
              </template>
            </a-card-meta>

            <a-row type="flex">
              <a-col flex="80px">单位地址</a-col>
              <a-col flex="auto">{{ item.address }}</a-col>
            </a-row>
            <a-row type="flex">
              <a-col flex="80px">电话</a-col>
              <a-col flex="auto">{{ item.phone }}</a-col>
            </a-row>
            <a-row type="flex">
              <a-col flex="80px">开户银行</a-col>
              <a-col flex="auto">{{ item.bank }}</a-col>
            </a-row>
            <a-row type="flex">
              <a-col flex="80px">账户</a-col>
              <a-col flex="auto">{{ item.account }}</a-col>
            </a-row>
            <a-row type="flex">
              <a-col flex="80px">图片</a-col>
              <a-col flex="auto">
                <a-image :width="100" :src="item.fileUrl"></a-image>
              </a-col>
            </a-row>
            <a-row type="flex" justify="end">
              <a-col>
                <a-button type="primary" :loading="loading" @click="choiceSuccess(item)">选择</a-button>
              </a-col>
            </a-row>

            <template #actions>
              <setting-outlined key="setting" />
              <edit-outlined key="edit" />
              <DeleteFilled @click="onclick(item.id, '2')" />
            </template>
          </a-card>
        </a-list-item>
      </template>
    </a-list>
  </a-modal>
</template>
<script>
import { start } from '@/api/system/bpm/instance'
import {fileUpload as uploadBpm } from '@/api/marketing/modList'
import { onMounted, reactive, ref, toRefs, watch } from 'vue'
import { message } from 'ant-design-vue'
import { fuzzyAll } from '@/api/crm/carrer/carrier'
import { getMemberRel } from '@/api/carrier/cloudFlash'
import { CameraOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { amountUppercase } from '@/utils/util'
import { queryBankCard } from '@/api/carrier/cloudFlash'
import { page } from '@/api/crm/carrer/invoice'
function getBase64 (img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}
export default {
  components: {
    CameraOutlined,
    PlusOutlined
  },
  props: {
    flowType: { type: Number, default: 0 },
    customerType: { type: Number, default: 0 }, //2 承运商，1客户
    customerName: { type: String, default: 0 },
    amount: { type: Number },
    cashAmt: { type: Number, default: 0 },
    oilAmt: { type: Number, default: 0 },
    cardAmt: { type: Number, default: 0 },
    customerId: { type: Number, default: 0 },
    settlementInfo: { type: Object, default: null }
  },
  setup (props, { emit }) {
    const formRef = ref()
    const onlineFormRef = ref(null)
    const state = reactive({
      loading: false,
      visible: false,
      applyAccounts: {
        forms: [],
        remark: undefined
      },
      fetching: false,
      timeOut: null,
      onlineData: {
        objectType: props.customerType,
        objectId: props.customerId || null,
        objectName: props.customerName,
        memberAlias: '',
        bizUserId: null
      },
      onlineRules: {
        objectType: { required: true, message: '请选择收款方类型', trigger: 'blur', type: 'number' },
        objectId: { required: true, message: '请选择', trigger: 'blur' },
        bizUserId: { required: true, message: '请选择会员账号', trigger: 'blur' }
      },
      mirrorOnlineData: {},
      nameTitle: '',
      vipName: '',
      bankNo: '',
      vipTitle: '',
      customerId: props.customerId,
      previewOtherImage: '',
      clientName: props.customerName || '',
      previewOtherVisible: false,
      otherimgList: [],
      fileIds: [],
      clientList: [],
      bankNoList: [],
      VIPaccountList: [],
      controlCheck: true,
      listData: [],
      pagination: {}
    })

    const initPay = () => {
      state.mirrorOnlineData = JSON.stringify(state.onlineData)
      if (props.settlementInfo && props.settlementInfo.orderSettlementIds && props.settlementInfo.orderSettlementIds.length > 0) {
        state.controlCheck = false
      }
      if (props.oilAmt > 0) {
        state.applyAccounts.forms.push({ project: '2', projectName: '油卡', flag: props.oilAmt > 0, amt: props.oilAmt, fileList: [] })
      }
      if (props.cardAmt > 0) {
        state.applyAccounts.forms.push({ project: '3', projectName: '转账', flag: props.cardAmt > 0, amt: props.cardAmt, fileList: [] })
      }
    }
    onMounted(initPay)

    const fileRemove = (form) => {
      form.fileList = []
    }
    const fileBeforeUpload = (info, form) => {
    }
    const beforeOtherUpload = file => {
      state.otherimgList = [...state.otherimgList, file]
      return false
    }
    const fileOnload = (allImgFile, i, j) => {
      if (allImgFile.length <= i) {
        bpmStart()
        return
      } else if (allImgFile[i].length === 0) {
        fileOnload(allImgFile, i + 1, 0)
      }
      const formData = new FormData()
      formData.append('file', allImgFile[i][j].originFileObj)
      uploadBpm(formData).then(res => {
        if (res.code === 10000) {
          if (i !== 2) {
            state.applyAccounts.forms[i].payeeFileId = res.data.id
          } else {
            state.fileIds.push(res.data.id)
          }
          formData.delete('file')
          if (i + 1 >= allImgFile.length) {
            if (allImgFile[i][j + 1] === undefined) {
              bpmStart()
            } else {
              fileOnload(allImgFile, i, j + 1)
            }
          } else {
            fileOnload(allImgFile, i + 1, 0)
          }
        }
      }).catch(err => {
        console.log(err)
        state.loading = false
      })
    }
    const bpmStart = () => {
      if (props.flowType === 1) {
        requireAPI()
        // onlineFormRef.value.validate().then(() => {
        // })
      } else {
        requireAPI()
      }
      function requireAPI () {
        start({
          bizParams: {
            applyAccounts: state.applyAccounts.forms,
            remark: state.applyAccounts.remark,
            ...props.settlementInfo
          },
          bankCardId: state.bankCardId,
          ...state.onlineData,
          fileIds: state.fileIds,
          // 用流程类型代替 id
          type: props.flowType
        }).then(async res => {
          if (res.code === 10000) {
            message.success(res.msg)
            emit('ok', res.data)
            state.fileIds = []
            state.onlineData = JSON.parse(state.mirrorOnlineData)
          } else {
            // message.error(res.msg)
          }
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          state.loading = false
        })
      }
    }
    const instanceStart = () => {
      formRef.value.validate()
        .then(() => {
          state.loading = true
          state.applyAccounts.forms = state.applyAccounts.forms.filter(item => {
            return item.flag
          })
          let flag = false
          const allFile = []
          if (state.applyAccounts.forms[0] && state.applyAccounts.forms[0].fileList.length > 0) {
            allFile[0] = state.applyAccounts.forms[0].fileList
            flag = true
          } else {
            allFile[0] = []
          }
          if (state.applyAccounts.forms[1] && state.applyAccounts.forms[1].fileList.length > 0) {
            allFile[1] = state.applyAccounts.forms[1].fileList
            flag = true
          } else {
            allFile[1] = []
          }
          if (state.otherimgList && state.otherimgList.length > 0) {
            allFile[2] = state.otherimgList
            flag = true
          }
          if (flag) {
            fileOnload(allFile, 0, 0)
          } else {
            bpmStart()
          }
        })
    }
    const handleOtherPreview = async file => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj, base64Url => {
          state.previewOtherImage = base64Url
        })
      }
      state.previewOtherVisible = true
    }
    const handleOtherCancel = () => {
      state.previewOtherVisible = false
    }
    const choiceCarrier = (parameter) => {
      state.visible = true
      loadData()
    }
    const choiceSuccess = (parameter) => {
      state.applyAccounts.forms[0].accountNum = parameter.account
      state.applyAccounts.forms[0].accountName = parameter.name
      state.applyAccounts.forms[0].accountBank = parameter.bank
      state.fileIds.push(parameter.fileId)
      state.visible = false
    }
    const handleCancel = () => {
      state.visible = false
    }
    const loadData = async () => {
      state.loading = true
      const { data, data: { records } } = await page({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }, props.customerId)
      state.listData = records
      state.pagination.current = data.current
      state.pagination.pageSize = data.size
      state.pagination.total = data.total
      state.loading = false
    }
    const typeChange = (e) => {
      state.onlineData.objectType = e
      state.onlineData.objectId = ''
      state.clientName = null
      state.vipName = null
      state.bankNo = null
      state.clientList = []
      state.VIPaccountList = []
      if (e === 1) {
        state.nameTitle = '选择客户'
        state.vipTitle = '客户会员账号'
      } else {
        state.nameTitle = '选择承运商'
        state.vipTitle = '承运商会员账号'
      }
    }
    watch(() => props.customerId, (newVal, oldVal) => {
      typeChange(props.customerType)
      carrierClient()
    }, { deep: true })
    const searchClient = (value) => {
      if (value === '' || value === null) clearTimeout(state.timeOutId), state.fetching = false
      if (value.length > 1) {
        state.fetching = true
        if (state.timeOutId !== null) {
          clearTimeout(state.timeOutId)
        }
        state.timeOutId = setTimeout(() => {
          fuzzyAll({ name: value, type: state.onlineData.objectType }).then(res => {
            if (res.code === 10000) { state.clientList = res.data }
            else state.clientList = []
          }).finally(() => { state.fetching = false })
        }, 1000)
      } else {
        state.clientList = []
      }
    }
    const carrierClient = item => {
      if (item) {
        state.onlineData.objectId = item.id
        state.onlineData.objectName = item.name
      }
      getMemberRel({ objectId: state.onlineData.objectId }).then(res => {
        if (res.code === 10000) {
          state.VIPaccountList = res.data
          console.log(state.VIPaccountList);
        }
      })
    }
    const chooseVIP = item => {
      state.onlineData.bizUserId = item.bizUserId
      state.onlineData.memberAlias = item.memberAlias
      let index = null
      if (state.applyAccounts.forms[0].project == 3) index = 0
      else index = 1
      state.applyAccounts.forms[index].accountName = item.memberName
      state.bankNoList = []
      state.bankCardId = null
      state.bankNo = null
      state.applyAccounts.forms[index].mod = ''
      state.applyAccounts.forms[index].accountBank = ''
      state.applyAccounts.forms[index].name = ''
      state.applyAccounts.forms[index].accountNum = ''
      queryBankCard({
        bizUserId: item.bizUserId
      }).then(res => {
        if (res.code === 10000) {
          state.bankNoList = res.data
        }
      })
    }
    const chooseBankCarNoChange = item => {
      let index = null
      if (state.applyAccounts.forms[0].project == 3) index = 0
      else index = 1
      state.bankCardId = item.id
      state.applyAccounts.forms[index].mod = '银行卡'
      state.applyAccounts.forms[index].accountBank = item.bankName
      state.applyAccounts.forms[index].name = item.bankName
      state.applyAccounts.forms[index].accountNum = item.bankTrueCard
    }
    return {
      ...toRefs(state),
      formRef,
      onlineFormRef,
      loadData,
      handleCancel,
      choiceSuccess,
      fileRemove,
      fileBeforeUpload,
      choiceCarrier,
      instanceStart,
      amountUppercase,
      carrierClient,
      bpmStart,
      chooseVIP,
      chooseBankCarNoChange,
      typeChange,
      searchClient,
      beforeOtherUpload,
      handleOtherPreview,
      handleOtherCancel
    }
  }
}
</script>
<style lang="less" scoped>
:deep(.ant-form-item) {
  margin-bottom: 6px !important;
}

:deep(.ant-divider-horizontal) {
  margin: 15px 0 !important;
}

.clearfix {
  width: 128px;
  height: 128px;
  border: 1px dashed gray;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.modal_card {
  width: 400px;
}

.qa {
  transform: rotateY(180deg)
}
</style>

<template>
  <a-form ref="formRef" :model="form" :rules="rules" :labelCol="{flex:'100px'}" scrollToFirstError>
    <a-form-item :wrapperCol="{flex:'auto'}" label="收款总金额">
      <a-typography-text type="danger">{{amount}} 元 </a-typography-text>
      <a-typography-text type="secondary">{{amountUppercase(amount)}}</a-typography-text>
    </a-form-item>
    <a-form-item :wrapperCol="{flex:'auto'}" label="付款人" name="payer">
      <a-input v-model:value="form.payer" />
    </a-form-item>
    <a-form-item :wrapperCol="{flex:'auto'}" label="付款账户" name="payWay">
      <a-select v-model:value="form.payWay">
        <a-select-option value="中国银行（华月基本户）">中国银行（华月基本户）</a-select-option>
        <a-select-option value="建设银行（华月一般户）">建设银行（华月一般户）</a-select-option>
        <a-select-option value="建设银行（恒宝账户）">建设银行（恒宝账户）</a-select-option>
        <a-select-option value="农业银行（华月账户）">农业银行（华月账户）</a-select-option>
        <a-select-option value="工商银行（华月账户）">工商银行（华月账户）</a-select-option>
        <a-select-option value="华夏银行（华月账户）">华夏银行（华月账户）</a-select-option>
        <a-select-option value="成都银行（华月账户）">成都银行（华月账户）</a-select-option>
        <a-select-option value="华夏银行（王雪松）">华夏银行（王雪松）</a-select-option>
        <a-select-option value="华夏银行（张天朋）">华夏银行（张天朋）</a-select-option>
        <a-select-option value="华夏银行（龚孙波）">华夏银行（龚孙波）</a-select-option>
        <a-select-option value="成都银行（久智账户）">成都银行（久智账户）</a-select-option>
        <a-select-option value="支付宝（华月物流成都办事处结算）">支付宝（华月物流成都办事处结算）</a-select-option>
        <a-select-option value="支付宝（王雪松）">支付宝（王雪松）</a-select-option>
        <a-select-option value="微信（华月物流成都办事处）">微信（华月物流成都办事处）</a-select-option>
        <a-select-option value="微信（康康）">微信（康康）</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item :wrapperCol="{flex:'auto'}" label="付款时间" name="paymentTime">
      <a-date-picker v-model:value="form.paymentTime" style="width:100%;" format="YYYY-MM-DD HH:mm:ss" valueFormat="YYYY-MM-DD HH:mm:ss" />
    </a-form-item>
    <a-form-item :wrapperCol="{flex:'auto'}" label="付款截图">
      <a-upload v-model:file-list="imgFileList" accept="image/*" list-type="picture" :remove="handleRemove" @preview="handlePreview" :before-upload="beforeUpload">
        <a-button :disabled="imgFileList.length > 0">
          <CameraOutlined />
          图片
        </a-button>
      </a-upload>
    </a-form-item>
    <a-form-item :wrapperCol="{flex:'auto'}" label="备注">
      <a-textarea v-model:value="form.remark" />
    </a-form-item>
    <a-form-item :wrapperCol="{flex:'auto'}" label="上传其他图片">
      <a-upload list-type="picture" accept="image/*" v-model:file-list="otherimgList" @preview="handleOtherPreview" :before-upload="beforeOtherUpload">
        <div class="clearfix" v-if="otherimgList.length < 8">
          <plus-outlined />
          <div class="ant-upload-text">上传</div>
        </div>
      </a-upload>
    </a-form-item>
  </a-form>
  <a-modal :visible="previewOtherVisible" :footer="null" @cancel="handleOtherCancel">
    <img alt="预览图片" style="width: 100%" :src="previewOtherImage" />
  </a-modal>
  <div :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 1,
      }">
    <a-button type="primary" :loading="loading" @click="instanceStart">提交</a-button>
  </div>
  <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
    <img alt="预览图片" style="width: 100%" :src="previewImage" />
  </a-modal>
</template>
<script>
import { start } from '@/api/system/bpm/instance'
import { fileUpload as uploadBpm } from '@/api/marketing/modList'
import { reactive, ref, toRefs } from 'vue'
import { message, Modal } from 'ant-design-vue'
import { CameraOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { amountUppercase } from '@/utils/util'

function getBase64 (img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}
export default {
  props: {
    flowType: { type: Number },
    amount: { type: Number, default: 0 },
    settlementInfo: { type: Object, default: null }
  },
  emits: ['ok'],
  setup (props, { emit }) {
    const formRef = ref()
    const state = reactive({
      previewImage: '',
      previewVisible: false,
      loading: false,
      imgFileList: [],
      previewOtherImage: '',
      previewOtherVisible: false,
      otherimgList: [],
      fileIds: [],
      form: {
        customerName: '',
        payer: '',
        paymentfileId: '',
        paymentTime: '',
        remark: ''
      },
      rules: {
        payer: { required: true, message: '请填写付款人', trigger: 'blur' },
        payWay: { required: true, message: '请选择付款账户', trigger: 'change' },
        paymentTime: { required: true, message: '请选择付款时间', trigger: 'change' }
      }
    })
    const handleRemove = file => {
      const index = state.imgFileList.indexOf(file)
      const newFileList = state.imgFileList.slice()
      newFileList.splice(index, 1)
      state.imgFileList = newFileList
    }

    const beforeUpload = file => {
      state.imgFileList = [...state.imgFileList, file]
      return false
    }
    const beforeOtherUpload = file => {
      state.otherimgList = [...state.otherimgList, file]
      return false
    }
    const fileonload = (i) => {
      const allImgFile = state.imgFileList.concat(state.otherimgList)
      const formData = new FormData()
      formData.append('file', allImgFile[i].originFileObj)
      uploadBpm(formData).then(res => {
        if (res.code === 10000) {
          if (i === 0) {
            state.form.paymentfileId = res.data.id
          } else {
            state.fileIds.push(res.data.id)
          }
          formData.delete('file')
          if (i + 1 >= allImgFile.length) {
            // props.settlementInfo.orderSettlementIds.forEach(item => {
            //   item.applyAmt = props.amount
            // })
            start({
              bizParams: {
                collectionAccount: state.form,
                ...props.settlementInfo
              },
              fileIds: state.fileIds,
              flowId: '',
              // 用流程类型代替 id
              type: props.flowType
            }).then(res => {
              if (res.code === 10000) {
                message.success(res.msg)
                emit('ok', res.data)
                state.fileIds = []
              } else {
                message.error(res.msg)
              }
            }).catch(err => {
              console.log(err)
            }).finally(() => {
              state.loading = false
            })
          }
          if (allImgFile.length > 1) {
            if (i + 1 >= allImgFile.length) {
              return
            }
            fileonload(i + 1)
          }
        }
      }).catch(err => {
        console.log(err)
        state.loading = false
      })
    }
    const instanceStart = () => {
      state.form.amt = props.amount
      formRef.value.validate()
        .then(() => {
          if (props.settlementInfo) {
            if (state.imgFileList.length > 0) {
              state.loading = true
              fileonload(0)
              // const formData = new FormData()
              // state.imgFileList.forEach(file => {
              //   formData.append('file', file.originFileObj)
              //   upload(formData).then(res => {
              //     if (res.code === 10000) {
              //       state.form.paymentfileId = res.data.id
              //       console.log(1, formData.getAll('file'))
              //       formData.delete('file')
              //     }
              //   }).catch(err => {
              //     console.log(err)
              //     state.loading = false
              //   })
              // })
              // state.otherimgList.forEach((file, index) => {
              //   formData.append('file', file.originFileObj)
              //   console.log(2, formData.getAll('file'))
              //   upload(formData).then(res => {
              //     if (res.code === 10000) {
              //       state.fileIds.push(res.data.id)
              //       console.log(3, formData.getAll('file'))
              //       formData.delete('file')
              //       // if (index === state.otherimgList.length - 1) {
              //       //   start({
              //       //     bizParams: {
              //       //       collectionAccount: state.form,
              //       //       ...props.settlementInfo
              //       //     },
              //       //     fileIds: state.fileIds,
              //       //     flowId: '',
              //       //     // 用流程类型代替 id
              //       //     type: props.flowType
              //       //   }).then(res => {
              //       //     if (res.code === 10000) {
              //       //       message.success(res.msg)
              //       //       emit('ok', res.data)
              //       //       state.fileIds = []
              //       //       console.log(res)
              //       //     } else {
              //       //       message.error(res.msg)
              //       //     }
              //       //   }).catch(err => {
              //       //     console.log(err)
              //       //   }).finally(() => {
              //       //     state.loading = false
              //       //   })
              //       // }
              //     }
              //   }).catch(err => {
              //     console.log(err)
              //     state.loading = false
              //   })
              // })
            } else {
              Modal.warning({ content: '请先上传付款截图' })
            }
          } else {
            Modal.error({ content: '订单选择出错' })
          }
        })
    }
    const handlePreview = async file => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj, base64Url => {
          state.previewImage = base64Url
        })
      }

      state.previewVisible = true
    }
    const handleOtherPreview = async file => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj, base64Url => {
          state.previewOtherImage = base64Url
        })
      }
      state.previewOtherVisible = true
    }
    const handleCancel = () => {
      state.previewVisible = false
    }
    const handleOtherCancel = () => {
      state.previewOtherVisible = false
    }
    return {
      formRef,
      ...toRefs(state),
      instanceStart,
      beforeUpload,
      handleRemove,
      amountUppercase,
      handlePreview,
      handleCancel,
      handleOtherPreview,
      handleOtherCancel,
      beforeOtherUpload,
      fileonload
    }
  },
  components: {
    CameraOutlined,
    PlusOutlined
  }
}
</script>
<style lang="less" scoped>
.clearfix {
  width: 128px;
  height: 128px;
  border: 1px dashed gray;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>

import {
  postBodyRequest,
  putRequest
} from '@/utils/axios'

export function page (params, carrierId) {
  return postBodyRequest(`/carrier/carrier/${carrierId}/invoice/page`, params)
}

export function edit (params, carrierId) {
  return postBodyRequest(`/carrier/carrier/${carrierId}/invoice/edit`, params)
}

export function add (params, carrierId) {
  return postBodyRequest(`/carrier/carrier/${carrierId}/invoice/add`, params)
}
export function deleteId (params, carrierId, invoiceId) {
  return putRequest(`/carrier/carrier/${carrierId}/invoice/${invoiceId}/delete`, params)
}

<template>
  <a-card title="账单查询">
    <a-form :model="form" layout="inline">
      <a-form-item label="" style="width: 13%">
        <a-input v-model:value="form.customerName" placeholder="客户名" />
      </a-form-item>
      <a-form-item label="" style="width: 13%">
        <a-input v-model:value="form.sponsor" placeholder="对账人" />
      </a-form-item>
      <a-form-item label="" style="width: 13%">
        <a-input v-model:value="form.orderId" placeholder="订单号" />
      </a-form-item>
      <a-form-item label="" style="width: 13%">
        <a-input v-model:value="form.id" placeholder="账单号" />
      </a-form-item>
      <a-form-item label="" style="width: 13%">
        <a-select v-model:value="form.status" style="width:100%" allowClear placeholder="状态">
          <a-select-option :value=0>新增对账</a-select-option>
          <a-select-option :value=1>已申请</a-select-option>
          <a-select-option :value=2>已完成</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="" style="width: 13%">
        <a-space>
          <a-button type="primary" @click="onSearch">查询</a-button>
          <a-button @click="addReconciliation" danger>新增对账</a-button>
          <a-button @click="mergeReconciliation">合并账单</a-button>
        </a-space>
      </a-form-item>
    </a-form>
  </a-card>
  <a-table :row-selection="{ selectedRowKeys: selectedReconciliationKeys, onChange: selectReconciliationChange }"
    :dataSource="reconciliationList" :loading="loading" :pagination="reconciliationPagination"
    :rowKey="record => record.id" size="small" bordered>
    <a-table-column title="账单号" data-index="id" />
    <a-table-column title="对账时间" data-index="createTime" />
    <a-table-column title="客户名" data-index="customerName" />
    <a-table-column title="流程编号" data-index="flowInstanceId" />
    <a-table-column title="对账金额" data-index="amt" />
    <a-table-column title="对账人" data-index="sponsor" />
    <a-table-column title="状态" data-index="status.label" />
    <a-table-column :width="80" title="图片">
      <template #default="{ record }">
        <a-image v-if="record.fileId !== null" :width="80" :src="record.fileUrl" :preview="record.preview"
          @click="handlePreview(record)" />
      </template>
    </a-table-column>
    <a-table-column :width="100" title="操作">
      <template #default="{ record }">
        <a-space>
          <a @click="generateDetails(record.id)">查看</a>
          <a-popconfirm title="确认删除吗?" @confirm="deleteBill(record.id)">
            <a>删除</a>
          </a-popconfirm>
        </a-space>
      </template>
    </a-table-column>
  </a-table>
  <a-modal v-model:visible="modalVisible" title="对账" width="95%" footer="" destroyOnClose>
    <Reconciliation @onSearch="onSearch" />
  </a-modal>
  <a-modal v-model:visible="modalDetailVisible" title="对账明细" width="95%" footer="" destroyOnClose>
    <ReconciliationDetail :reconciliationId="reconciliationId" />
  </a-modal>
  <a-modal v-model:visible="visibleMerge" title="合并对账账单" width="95%" :maskClosable='false' @ok="mergeStart">
    <b>合并后金额：</b><span style="color: indianred">{{ mergeAmt }}</span>
    <a-table :dataSource="mergeList" :loading="loading" :rowKey="record => record.id" size="small" bordered>
      <a-table-column title="对账时间" data-index="createTime" />
      <a-table-column title="客户名" data-index="customerName" />
      <a-table-column title="对账金额" data-index="amt" />
      <a-table-column title="对账人" data-index="sponsor" />
      <a-table-column title="状态" data-index="status.label" />
    </a-table>
    <b>请注意：合并后之前的对账单会合并成一个，客户名只会保留最后一个的，且只能合并你自己的对账单。</b>
  </a-modal>
</template>
<script>

import { reconciliationDelete, reconciliationPage, reconciliationMerge } from '@/api/trans/finance/bill'
import { reactive, ref, toRefs, onMounted } from 'vue'
import Reconciliation from './Reconciliation'
import ReconciliationDetail from './ReconciliationDetail'
import { message } from 'ant-design-vue'

export default {
  components: { Reconciliation, ReconciliationDetail },
  setup () {
    const reconciliationListRef = ref()
    const transportListRef = ref()
    const paymentRef = ref()
    const state = reactive({
      visibleMerge: false,
      mergeList: [],
      mergeAmt: 0,
      loading: false,
      modalVisible: false,
      modalDetailVisible: false,
      form: {},
      orderSearchForm: { date: [] },
      transportSearchForm: { date: [] },
      carriers: [],
      total: {},
      selectedReconciliationKeys: [],
      reconciliationId: '',
      reconciliationList: [],
      reconciliationPagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`,
        onChange: (page, pageSize) => {
          state.reconciliationPagination.current = page
          state.reconciliationPagination.pageSize = pageSize
          loadOrderPage()
        }
      },
      timeOut: null
    })
    const onSearch = () => {
      state.reconciliationPagination.current = 0
      state.selectedReconciliationKeys = []
      state.mergeList = []
      orderSearchData()
    }

    const loadOrderPage = () => {
      state.loading = true
      reconciliationPage({
        ...state.form,
        // startTime: state.orderSearchForm.date[0],
        // endTime: state.orderSearchForm.date[1],
        current: state.reconciliationPagination.current,
        size: state.reconciliationPagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.reconciliationPagination.current = res.data.current
          state.reconciliationPagination.pageSize = res.data.size
          state.reconciliationPagination.total = res.data.total
          state.reconciliationList = res.data.records
        } else {
          state.reconciliationList = []
        }
      }).finally(() => {
        state.loading = false
      })
    }
    const orderSearchData = () => {
      state.reconciliationList = []
      state.reconciliationPagination = {}
      loadOrderPage()
    }
    const addReconciliation = () => {
      state.modalVisible = true
    }
    const generateDetails = (id) => {
      state.reconciliationId = id
      state.modalDetailVisible = true
    }
    const deleteBill = (id) => {
      reconciliationDelete(id).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          onSearch()
        }
      })
    }
    const selectReconciliationChange = (selectedRowKeys, selectedRows) => {
      state.selectedReconciliationKeys = selectedRowKeys
      state.mergeList = selectedRows
    }
    const mergeReconciliation = () => {
      state.mergeAmt = 0
      let flag = true
      if (state.mergeList.length === 0) {
        flag = false
        message.error('请选择需要合并的账单')
      } else {
        state.mergeList.forEach(item => {
          if (item.status.value !== 0) {
            flag = false
            state.mergeAmt = 0
          }
          state.mergeAmt += Number(item.amt)
        })
        setTimeout(() => {
          if (flag) {
            state.visibleMerge = true
          } else {
            message.error('合并时，状态只能是 新增对账，才可合并')
          }
        }, 200)
      }
    }
    const mergeStart = () => {
      reconciliationMerge({
        ids: state.selectedReconciliationKeys
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          state.visibleMerge = false
          onSearch()
        }
      })
    }
    const handlePreview = (item) => {
      item.preview = true
      item.fileUrl = item.fileRealUrl
    }
    onMounted(onSearch)
    return {
      ...toRefs(state),
      reconciliationListRef,
      transportListRef,
      paymentRef,
      onSearch,
      deleteBill,
      loadOrderPage,
      orderSearchData,
      addReconciliation,
      generateDetails,
      selectReconciliationChange,
      mergeReconciliation,
      mergeStart,
      handlePreview
    }
  }
}
</script>
<style lang="less" scoped>
@import 'index.less';
</style>

<template>
  <a-card :style="{ marginTop: '24px' }" :bodyStyle="{padding:0}">
    <a-tag color="pink" class="select-table-info">
      <template #icon>
        <InfoCircleFilled />
      </template>
      已选择 <strong>{{selectedTransRowKeys.length}}</strong> 项
      运单金额总计 <strong>{{orderAmt}}</strong> 元
      <a @click="selectedTransRowKeys = []">清空选择</a>
    </a-tag>
    <a-button @click="editReconciliation" type="primary">添加</a-button>
    <template #extra>
      <a-space>
        <a-input v-model:value="orderreconciliationAdd.orgName" placeholder="派车部门"></a-input>
        <a-input v-model:value="orderreconciliationAdd.operator" placeholder="派车人"></a-input>
        <a-input v-model:value="orderreconciliationAdd.vinNo" placeholder="小车车牌"></a-input>
        <a-input v-model:value="orderreconciliationAdd.carNo" placeholder="大车车牌"></a-input>
        <a-range-picker v-model:value="orderreconciliationAdd.date" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" @change="orderSearchData" />
        <a-button type="primary" @click="orderSearchData">查询</a-button>
      </a-space>
    </template>
    <a-table :row-selection="{ selectedRowKeys: selectedTransRowKeys, onChange: selectTransChange }" :dataSource="transportList" :rowKey="record => record.id" size="small" bordered>
      <a-table-column :width="200" title="运单编号" data-index="transId" />
      <a-table-column :width="100" title="车牌号/司机" data-index="transName" />
      <a-table-column :width="100" title="承运商" data-index="carrierName" />
      <a-table-column :width="100" title="线路" data-index="lineName" />
      <a-table-column :width="100" title="发车时间" data-index="departureTime" />
      <a-table-column :width="100" title="类型" data-index="truckType.label" />
      <a-table-column :width="100" title="派车人" data-index="loader" />
      <a-table-column :width="100" title="到达时间" data-index="arrivalTime" />
      <a-table-column :width="100" title="总结算金额" data-index="settableAmt" />
      <a-table-column :width="120" title="小车信息" data-index="vehicleList">
        <template #default="{ record }">
          <div v-for="(item,index) in record.vehicleList" :key="index">
            <a-tag color="#108ee9" style="cursor: pointer;">车架号：{{item.vinNo}} 金额：{{item.transportFee}}元</a-tag><br />
          </div>
        </template>
      </a-table-column>
      <a-table-column :width="100" title="油卡结算金额" data-index="settableOilAmt" />
      <a-table-column :width="100" title="转账结算金额" data-index="settableCardAmt" />
    </a-table>
  </a-card>
</template>
<script>
import { billCarrierTrans, reconciliationEdit } from '@/api/trans/finance/bill'
import { onMounted, reactive, ref, toRefs } from 'vue'
import { message } from 'ant-design-vue'
export default {
  props: { customerId: { type: String, default: '' }, type: { type: String, default: '' }, reconciliationId: { type: String, default: '' } },
  setup (props, { emit }) {
    const orderListRef = ref()
    const transportListRef = ref()
    const paymentRef = ref()
    const state = reactive({
      selectedTransRowKeys: [],
      orderreconciliationAdd: { date: [] },
      transportreconciliationAdd: { date: [] },
      reconciliation: {
        id: ''
      },
      total: {},
      transportList: [],
      transportPagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`,
        onChange: (page, pageSize) => {
          state.transportPagination.current = page
          state.transportPagination.pageSize = pageSize
          loadOrderPage()
        }
      },
      timeOut: null,
      orderAmt: 0
    })

    const loadOrderPage = () => {
      state.reconciliation.id = props.reconciliationId
      billCarrierTrans(props.customerId, {
        ...state.orderreconciliationAdd,
        billStatus: 0,
        type: props.type,
        startTime: state.orderreconciliationAdd.date[0],
        endTime: state.orderreconciliationAdd.date[1]
      }).then(res => {
        if (res.code === 10000) {
          state.transportList = res.data
        } else {
          state.transportList = []
        }
      })
    }

    const orderSearchData = () => {
      state.orderCheckedList = []
      state.selectedTransRowKeys = []
      state.transportPagination = {}
      loadOrderPage()
    }

    const selectTransChange = (selectedRowKeys, selectedRows) => {
      state.selectedTransRowKeys = selectedRowKeys
      state.reconciliation.transSettlementIds = selectedRowKeys.map(item => { return { id: item } })
      clearAccounts()
    }

    const clearAccounts = () => {
      state.orderAmt = 0
      state.transportList.forEach(res => {
        if (state.selectedTransRowKeys.includes(res.id)) {
          state.orderAmt += Number(res.settableAmt)
        }
      })
    }
    const editReconciliation = () => {
      reconciliationEdit({
        ...state.reconciliation
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          emit('ok')
          orderSearchData()
        }
      })
    }

    onMounted(loadOrderPage)
    return {
      ...toRefs(state),
      orderListRef,
      transportListRef,
      paymentRef,
      loadOrderPage,
      orderSearchData,
      selectTransChange,
      editReconciliation
    }
  }
}
</script>
<style lang="less" scoped>
@import 'index.less';
</style>
